<template>
  <div>
    <a
      rel="noreferrer"
      target="_blank"
      href="https://assoft.co"
      class="position-absolute  bottom-0 end-0 m-3"
      >Optima Health by Assoft</a
    >
    <form
      @submit.prevent="login"
      class="p-3 position-absolute top-50 start-50 translate-middle border rounded public-form"
    >
      <h3 class="text-center">Inicio de Sesión</h3>
      <p class="text-center">Bienvenido a Optima Health</p>
      <div class="mb-3 inputIcon">
        <label for="loginEmail" class="form-label">Correo Electrónico</label>
        <input
          autofocus
          type="email"
          class="form-control"
          id="loginEmail"
          aria-describedby="emailHelp"
          v-model="email"
          required
        />
        <svg class="bi" fill="currentColor">
          <use xlink:href="/img/icons/bootstrap-icons.svg#person" />
        </svg>
      </div>
      <div class="mb-3 inputIcon">
        <div class="row">
          <label for="loginPassword" class="col form-label">Contraseña</label>
          <router-link class="col-8 text-end mb-1" to="/forgotPassword"
            >¿Olvidó su Contraseña?</router-link
          >
        </div>
        <input
          type="password"
          class="form-control col-12"
          id="loginPassword"
          v-model="password"
          required
        />
        <svg class="bi" fill="currentColor">
          <use xlink:href="/img/icons/bootstrap-icons.svg#lock" />
        </svg>
      </div>
      <div class="d-grid gap-2">
        <button type="submit" class="btn btn-primary shadow-primary">
          Entrar
        </button>
      </div>
      <p class="mt-3 text-center">
        ¿Nuevo Usuario? <router-link to="/signup">Cree una Cuenta</router-link>
      </p>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Session from "@/modules/session";

export default {
  data() {
    return {
      email: "",
      password: ""
    };
  },
  name: "Login",
  methods: {
    login() {
      const result = new Session()
        .signInEmail(this.email, this.password)
        .then(user => {
          this.$router.push({ name: "Loader" });
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            title: "Correo Electrónico o Contraseña Incorrectos",
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
        });
    }
  }
};
</script>
